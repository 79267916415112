<template>
  <v-dialog v-model="showModal" persistent max-width="800px">
    <v-card>

      <v-card-title class="error">
        <v-icon class="mr-3" style="color: white;">warning</v-icon>
        <span style="color: white;">A server error has occurred</span>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-simple-table>
          <tbody>
            <tr>
              <td>Action</td>
              <td>
                <span class="text-break">{{getMethod(apiError)}}</span>
              </td>
            </tr>
            <tr>
              <td>Result</td>
              <td>
                <span class="text-break">{{getDescription(apiError)}}</span>
              </td>
            </tr>
            <tr>
              <td>External message</td>
              <td>
                <span class="text-break">{{getExtMsg(apiError)}}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'APIError',

  data () {
    return {
      showModal: false,
    }
  },

  computed: {
    apiError () {
      return this.$store.state.system.apiError
    },
  },

  watch: {
    apiError: {
      handler () {
        if (this.apiError) {
          this.showModal = true
        } else {
          this.showModal = false
        }
      },
    },
  },

  methods: {
    close () {
      this.$store.commit('system/setAPIError', null)
    },

    getMethod (apiErr) {
      if (!apiErr) {
        return ''
      }
      return apiErr.method + ' ' + apiErr.url
    },

    getDescription (apiErr) {
      if (!apiErr) {
        return ''
      }

      if (apiErr.description) {
        return apiErr.description
      }

      // use the phrase "Unknown error" if the description is empty
      return 'Unknown error'
    },

    getExtMsg (apiErr) {
      if (!apiErr) {
        return ''
      }

      if (apiErr.ext_msg) {
        return apiErr.ext_msg
      }

      return ''
    },
},
}
</script>
